import { Link } from "react-router-dom";
import { useWindowSize, replaceMenuName } from "../lib/Functions";
import { Caption, Detail, Grid, Image, InputText, Modal, Row } from "@maggioli-design-system/react";
import LoginButton from "./LoginButton";
import { IProdotto } from "../Interfaces/IProdotto";
import { ILoader } from "../Interfaces/ILoader";
import { ChangeEvent, useEffect } from "react";

interface ITopMenu {
    idProdotti: IProdotto[];
    setShowModal: Function;
    visible: boolean;
    setVisible: Function;
    ip: string;
    loader: ILoader;
    handler: {
        setMatch: Function;
        handlerText: {
            setSearchText: Function;
        };
    };
}

const TopMenu = ({ idProdotti, setShowModal, visible, setVisible, ip, handler, loader }: ITopMenu): JSX.Element => {
    const windowWidth = useWindowSize();

    useEffect(() => {
        loader.setLoader(idProdotti.length === 0);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [idProdotti.length]);

    const onClickCategory = (cat: IProdotto) => {
        handler.handlerText.setSearchText("");
        handler.setMatch((prev: Object) => ({
            ...prev,
            url: `/categoria/${cat.descrizione.replaceAll(" ", "-").toLowerCase()}/${cat.id}/1`,
            params: {
                id: cat.id,
                slug: cat.descrizione.replaceAll(" ", "-").toLowerCase(),
            },
        }));
    };

    const sortFilterProducts = () => {
        return idProdotti
            .filter((a) => a.id.toString() !== "200" && a.id.toString() !== "201")
            .sort((a, b) => {
                const nameA = replaceMenuName(a.descrizione);
                const nameB = replaceMenuName(b.descrizione);
                if (nameA < nameB) {
                    return -1;
                }
                if (nameA > nameB) {
                    return 1;
                }
                return 0;
            });
    };

    const wideMenu = sortFilterProducts().map((v, i): JSX.Element => {
        const path = window.location.pathname.split("/");
        const style = "header-category-button py-2 px-3";
        const selectStyle = " bg-adjust-tone-16 rounded-xl";
        const currentAreaID = path[path.length - 2];
        return (
            <Link key={i} to={`/categoria/${v.descrizione.replaceAll(" ", "-").toLowerCase()}/${v.id}/1`} onClick={() => onClickCategory(v)}>
                <Caption className={v.id.toString() === currentAreaID ? style + selectStyle : style} htmlTag="div">
                    {replaceMenuName(v.descrizione)}
                </Caption>
            </Link>
        );
    });

    const modalMenu = sortFilterProducts().map(
        (v, i): JSX.Element => (
            <Link key={i} to={`/categoria/${v.descrizione.replaceAll(" ", "-").toLowerCase()}/${v.id}/1`}>
                <Detail className="header-category-button py-2 px-3" htmlTag="div">
                    {replaceMenuName(v.descrizione)}
                </Detail>
            </Link>
        ),
    );

    return windowWidth < 1024 ? (
        <Modal visible={visible} onCancel={() => setVisible(false)} position="left" footer={false} contentOnly>
            <Grid className="padding-normal pt-24">
                <Image alt="logo biblioteca digitale" className="modal-logo" src={`${process.env.PUBLIC_URL}/assets/images/logo-biblioteca-digitale.png`} />
                <Grid template="auto-fit" gutter="xsmall">
                    <InputText
                        onChange={(e: ChangeEvent<HTMLFormElement>) => handler.handlerText.setSearchText(e.target.value)}
                        className="overflow-visible"
                        icon="data-search"
                        placeholder="Cerca nella Biblioteca..."
                    />
                    <LoginButton setShowModal={setShowModal} idProdotti={idProdotti} ip={ip} />
                </Grid>
            </Grid>
            <Grid gutter="none" className="padding-xsmall background-color-adjust-tone-19">
                {modalMenu}
            </Grid>
        </Modal>
    ) : (
        <div className="p-5 background-color-adjust-tone-20 header-category-buttons">
            <Row className="view-limit justify-center" gutter="none">
                {wideMenu}
            </Row>
        </div>
    );
};

export default TopMenu;
