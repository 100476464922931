import React, { ReactElement, useState } from 'react'
import {
    Grid,
    Select,
} from '@maggioli-design-system/react'

interface Props {
    selectFormat: Function,
}

export default function ILibroFilter({ selectFormat }: Props): ReactElement {

    const [format, setFormat] = useState<string>('Tutti');

    const setSelectFormat = (value: string) => {
        setFormat(value);
        selectFormat(value);
    }

    return (
        <Grid gutter="xsmall">
            <Select data={['Tutti', 'iLibro', 'Cartaceo']} value={format} 
                    onChange={(e) => setSelectFormat(e.target.value)} label="Formato"/>
        </Grid>
    )
}
